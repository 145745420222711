import React from "react";

import { Navigate, Outlet } from "react-router";
import { ProfileContext } from "../providers/ProfileProvider";
import { hasAnyGroup } from "../utils/groups";

interface Props extends React.PropsWithChildren {
  /** The user is allowed to access the route if they are a member of one or
   * more of the specified groups. */
  anyOneOfGroups: Set<string>;
  redirectPath?: string;
}

const PermissionedRoute = ({
  anyOneOfGroups,
  redirectPath = "/summary",
}: Props) => {
  const profile = React.useContext(ProfileContext);
  const isAllowed = hasAnyGroup(profile, anyOneOfGroups);

  if (!isAllowed) return <Navigate to={redirectPath} replace />;

  return <Outlet />;
};

export default PermissionedRoute;
